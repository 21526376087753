
import { Vue, Prop, Component, Emit } from "vue-property-decorator"
import Wizard from "@/components/Wizard/Wizard.vue"

@Component({
  components: {
    Wizard,
  },
})
export default class WizardDialog extends Vue {
  @Prop({ required: true })
  readonly value!: boolean
  @Prop() readonly backTo?: string

  @Emit("input")
  @Emit("close")
  onClose() {
    return false
  }
}
