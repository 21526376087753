
import { Vue, Prop, Component, InjectReactive } from "vue-property-decorator"

@Component
export default class WizardStep extends Vue {
  @Prop({ required: true }) readonly title!: string
  @Prop({ required: true }) readonly subtitle!: string
  @Prop() readonly nextHint?: string

  @InjectReactive() readonly steps!: number
  @InjectReactive() readonly currentStepIndex!: number

  get formProgress() {
    return ((this.currentStepIndex + 1) / this.steps) * 100
  }
}
